.button-icon {
  border-radius: var(--border-input);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 0px;
  outline: 0px;
  padding: 8px;
  background-color: transparent;
  cursor: pointer;
}
.button-icon:hover {
  background-color: var(--gray-lighter);
}
.button-icon:active {
  background-color: var(--gray-light);
}

.button-icon:disabled {
  cursor: not-allowed;
  background-color: transparent;
}
.button-icon:disabled:hover {
  background-color: transparent;
}

.button-icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-icon-hint {
  display: none;
  line-height: 29px;
  position: absolute;
  background-color: var(--body);
  border-radius: var(--border-input);
  top: calc(100% + 4px);
  padding: 0px 16px;
  white-space: nowrap;
  min-width: max-content;
  z-index: 100;
}
.button-icon-hint::after {
  content: '';
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid var(--body);
}

.button-icon-container:hover .button-icon-hint {
  display: flex;
}
