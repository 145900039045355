.inbox-threads-header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid var(--border);
}

.inbox-threads-header .inbox-threads-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
}

.inbox-threads-header .inbox-threads-header-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.inbox-threads-header .inbox-threads-header-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid var(--border);
  border-radius: var(--border-input);
  gap: 4px;
}

.inbox-threads-header .inbox-threads-header-checkbox-icon {
  margin: -4px;
  margin-right: -4px;
}

.inbox-threads-header .inbox-threads-header-actions-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  margin-top: -16px;
}
