.job-tracker-displayer-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: fit-content;
  height: fit-content;
  z-index: 25;
  pointer-events: none;
}

.job-tracker-displayer {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 8px;
  padding: 16px;
  max-height: calc(100vh - 32px);
  overflow: hidden;
}

.job-tracker-displayer::-webkit-scrollbar {
  width: 6px;
}

.job-tracker-displayer::-webkit-scrollbar-track {
  background: transparent;
}

.job-tracker-displayer::-webkit-scrollbar-thumb {
  background-color: var(--gray-2);
  border-radius: 3px;
}
