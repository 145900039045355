.progress-bar {
  width: 100%;
  background-color: var(--gray-light);
  border-radius: var(--border-input);
}

.progress-bar-fill {
  width: 100%;
  background-color: var(--primary);
  border-radius: var(--border-input);
}

.progress-bar-fill-animated {
  animation: fill-progress 1s ease-in-out;
}

@keyframes fill-progress {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
