.checkbox-container {
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-custom {
  position: relative;
  height: 14px;
  width: 14px;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: 4px;
  transition: all 0.12s ease-in-out;
}

.checkbox-container:hover .checkbox-custom {
  border-color: var(--border-hover);
}

.checkbox-container input:checked ~ .checkbox-custom {
  background-color: var(--primary);
  border-color: var(--primary);
  outline: 4px solid var(--primary-pale);
}

.checkbox-custom:after {
  content: '';
  position: absolute;
  display: none;
  left: 5px;
  top: 2px;
  width: 2px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-container input:checked ~ .checkbox-custom:after {
  display: block;
}

.checkbox-container input:disabled ~ .checkbox-custom {
  background-color: var(--border);
  border-color: var(--border);
  cursor: not-allowed;
}

.checkbox-container input:disabled ~ .checkbox-label {
  color: var(--gray);
  cursor: not-allowed;
}

.checkbox-label {
  padding-left: 8px;
  font-size: 14px;
}

.checkbox-container.aligned {
  align-items: flex-start;
}

.checkbox-container.aligned .checkbox-custom {
  margin-top: 3px;
}
