.job-tracker-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: var(--border-card);
  box-shadow: var(--shadow);
  transition: all 0.3s ease;
  animation: slideIn 0.3s ease;
  pointer-events: all;
}

.job-tracker-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.job-tracker-item-header-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.job-tracker-item-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: var(--border-input);
}

.job-tracker-item-header-title {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.job-tracker-item-header-close {
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.job-tracker-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.job-tracker-item-content-progress {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-bottom: -4px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.job-tracker-item.completed {
  animation: fadeOut 0.5s ease forwards;
}
