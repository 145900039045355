.overview-usage-card {
  display: flex;
  flex-direction: row;
  height: 160px;
  gap: 16px;
}

.overview-usage-card-left {
  display: flex;
  flex-direction: column;
  min-width: 196px;
  gap: 8px;
}

.overview-usage-card-right {
  flex: 1;
}

.overview-usage-card-left-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.overview-usage-card-left-item div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.overview-usage-card-left-item .b3 {
  margin-top: -2px !important;
}
