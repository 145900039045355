:root {
  --primary: #0066ff;
  --primary-hover: #0052cc;
  --primary-pale: #e6f2ff;
  --primary-gray: #f7fcfd;

  --white: #ffffff;
  --body: #303030;
  --gray: #767676;
  --gray-2: #a5a5a5;
  --gray-light: #eeeeee;
  --gray-lighter: #f1f1f1;
  --pale: #f5f5f5;
  --paler: #f9f9f9;

  --message: #e6f2ff;

  --hyperlink: #4dafff;

  --border: #eaeaea;
  --border-hover: #d4d4d4;

  --opacity: rgba(0, 0, 0, 0.25);

  --error: #c24f4f;

  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #c24f4f;

  --success: #07bc0c;
  --warning: #f1c40f;
  --info: #3498db;
  --error: #c24f4f;
  --pending: #f1c40f;
  --ready: #3498db;
  --active: #07bc0c;
  --inactive: #c24f4f;
  --finished: #9658b3;

  --success-pale: #e6f2e6;
  --warning-pale: #fff8e6;
  --info-pale: #e6f2ff;
  --error-pale: #ffe6e6;
  --pending-pale: #fff8e6;
  --ready-pale: #e6f2ff;
  --active-pale: #e6f2e6;
  --inactive-pale: #ffe6e6;
  --finished-pale: #f2e6ff;

  --success-hover: #07bc0c;
  --warning-hover: #f1c40f;
  --info-hover: #3498db;
  --error-hover: #b33b3b;

  --tag-red: #c24f4f;
  --tag-red-pale: #ffd4d4;
  --tag-yellow: #a59643;
  --tag-yellow-pale: #fff5d4;
  --tag-orange: #bd6636;
  --tag-orange-pale: #ffe8d4;
  --tag-green: #29a029;
  --tag-green-pale: #eafde5;
  --tag-blue: #2953a0;
  --tag-blue-pale: #e5e9fd;
  --tag-purple: #9658b3;
  --tag-purple-pale: #efcdff;
  --tag-pink: #e761a8;
  --tag-pink-pale: #ffe0f3;
  --tag-white: #747474;
  --tag-white-pale: #ffffff;

  --queue: #989898;
  --sending: #989898;
  --sent: #0066ff;
  --delivered: #0066ff;
  --read: #9034bb;
  --voicemail: #c24f4f;
  --failed: #c24f4f;
  --retrying: #f88336;
  --acepted: #32ac27;
  --converted: #32ac27;
  --rejected: #000;
  --unreachable: #c24f4f;
  --queue-pale: #f5f5f5;
  --sending-pale: #f5f5f5;
  --sent-pale: #e6f2ff;
  --delivered-pale: #e6f2ff;
  --read-pale: #f5f5f5;
  --voicemail-pale: #ffe6e6;
  --failed-pale: #ffe6e6;
  --retrying-pale: #fff8e6;
  --acepted-pale: #e6f2e6;
  --converted-pale: #e6f2e6;
  --rejected-pale: #f5f5f5;
  --unreachable-pale: #ffe6e6;

  --created: #989898;
  --queued: #989898;
  --ringing: #f88336;
  --in-progress: #32ac27;
  --forwarding: #f88336;
  --ended: #0066ff;
  --busy: #f88336;
  --no-answer: #c24f4f;
  --failed: #c24f4f;
  --created-pale: #f5f5f5;
  --queued-pale: #f5f5f5;
  --ringing-pale: #fff8e6;
  --in-progress-pale: #e6f2e6;
  --forwarding-pale: #fff8e6;
  --ended-pale: #e6f2ff;
  --busy-pale: #fff8e6;
  --no-answer-pale: #ffe6e6;
  --failed-pale: #ffe6e6;

  --unread: #e84848;

  --integration-whatsapp: #3ba33b;
  --integration-phone-call: #0066ff;
  --integration-api: #8d5240;

  --integration-whatsapp-pale: #e6f2e6;
  --integration-phone-call-pale: #e6f2ff;
  --integration-api-pale: #ffe8d4;

  --shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

  --border-card: 16px;
  --border-input: 8px;
  --border-tag: 4px;

  --animation-beizer: cubic-bezier(0.4, 0, 0.2, 1);

  --breakpoint-mobile: 480px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1024px;
}

* {
  transition: all 0.12s var(--animation-beizer);
}

form {
  display: flex;
  flex-direction: column;
}

.Toastify__toast {
  min-height: 48px !important;
  border-radius: var(--border-input) !important;
  box-shadow: var(--shadow) !important;
}
.Toastify__toast-body {
  margin: 0px !important;
  padding: 0px 16px !important;
  padding-bottom: 4px !important;
  gap: 8px !important;
  font-size: 14px !important;
}
.Toastify__toast-icon {
  width: 16px !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes hoverIn {
  from {
    transform: translate(0px, 4px) scale(0.95);
  }
  to {
    transform: translate(0px, 0px) scale(1);
  }
}
@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 100vh;
}
.loading-screen-container {
  background-color: var(--pale);
  border-radius: 50%;
  padding: 16px;
}
.not-found-screen {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
.dashboard-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 48px;
  overflow-x: visible;
  overflow-y: auto;
}

.skeleton-loader {
  position: relative;
  background-color: var(--pale) !important;
  overflow: hidden;
}
.skeleton-loader::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(255, 255, 255, 0.8) 50%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: shimmer 1.2s infinite;
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

/* KA Table overrides */

.ka {
  overflow: visible !important;
  overflow-x: visible !important;
}
.ka-table-wrapper {
  overflow: visible !important;
  overflow-x: visible !important;
}

th:first-child {
  border-radius: var(--border-input) 0px 0px 0px;
}
th:last-child {
  border-radius: 0px var(--border-input) 0px 0px;
}

.ka-thead-row {
  background-color: var(--pale);
}

.ka-thead-cell {
  background-color: transparent !important;
  padding: 8px 16px !important;
  color: var(--gray);
}

.ka-thead-cell-content {
  width: 100%;
  font-family: var(--display);
  font-size: 12px;
  font-weight: 500 !important;
}

.ka-cell {
  padding: 8px 16px;
  line-height: 29px;
  color: var(--body);
}

.ka-cell-editor-validation-error .ka-input {
  background: var(--pale);
  border: 1px solid var(--error);
}

.ka-validation-message {
  font-size: 12px;
  color: white;
  background-color: var(--error);
  padding: 0 10px;
  max-width: 200px;
}

.ka-row {
  box-sizing: border-box;
  border-bottom: 1px solid var(--pale);
  border-top: 1px solid var(--pale);
}

.ka-dragged-row {
  opacity: 0.5;
}

.ka-drag-over-row {
  box-shadow: inset 0 7px 0px -4px #e1ebf0;
}

.ka-dragged-row ~ .ka-drag-over-row {
  box-shadow: inset 0 -7px 0px -4px #e1ebf0;
}

.ka-drag-over-column {
  box-shadow: inset 7px 0 0px -4px #e1ebf0;
}

.ka-dragged-column ~ .ka-drag-over-column {
  box-shadow: inset -7px 0 0px -4px #e1ebf0;
}

.ka-row-selected {
  background-color: #f7fcfd;
}

.ka-group-row {
  background-color: transparent !important;
  box-sizing: border-box;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.ka-group-cell {
  padding: 8px 10px;
}

.ka-group-cell-content {
  display: flex;
  align-items: center;
}

.ka-icon-group-arrow {
  padding: 7px 5px 7px 10px;
  margin-right: 5px;
  cursor: pointer;
}

.ka-group-panel {
  display: flex;
}

.ka-group-panel-cell {
  margin-bottom: 15px;
  margin-top: 15px;
  margin-left: 15px;
  display: flex;
  gap: 15px;
  align-items: center;
}

.ka-group-panel-cell-remove {
  cursor: pointer;
  color: #b8bdc2;
  display: flex;
  fill: currentColor;
}

.ka-group-panel-text {
  color: #747d86;
  margin: 30px 20px;
}

.ka-icon-tree-arrow {
  padding: 0 10px 0 10px;
  margin-left: -10px;
  cursor: pointer;
}

.ka-icon-sort {
  margin-left: 8px;
}

.ka-icon-sort-arrow-up {
  color: #687078;
  fill: currentColor;
  position: relative;
  top: 1px;
}

.ka-icon-sort-arrow-down {
  color: #687078;
  fill: currentColor;
  position: relative;
  top: 1px;
}

.ka-pointer {
  cursor: pointer;
}

.ka-filter-row-cell {
  padding: 0 20px 15px 20px;
}

.ka-loading {
  position: absolute;
  left: 0;
  top: 64px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5333333333);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ka-loading-active {
  position: relative;
}

.ka-loading-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: ka-loading-animation 1s linear infinite alternate;
  position: relative;
  left: -20px;
}

.ka-thead-cell-resize {
  user-select: none;
  width: 2px;
  cursor: col-resize;
  background-color: #d7e4eb;
  position: relative;
  left: 19px;
}

.ka-thead-cell-resize:active:after {
  content: '';
  display: block;
  position: fixed;
  cursor: col-resize;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ka-thead-background {
  z-index: 1;
}

.ka-summary-cell {
  padding: 12px 20px;
}

@keyframes ka-loading-animation {
  0% {
    background-color: rgb(116, 125, 134);
    box-shadow:
      20px 0px 0px 0px rgba(14, 15, 15, 0.2),
      40px 0px 0px 0px rgba(116, 125, 134, 0.2);
  }
  25% {
    background-color: rgba(116, 125, 134, 0.4);
    box-shadow:
      20px 0px 0px 0px rgb(116, 125, 134),
      40px 0px 0px 0px rgba(116, 125, 134, 0.2);
  }
  75% {
    background-color: rgba(116, 125, 134, 0.4);
    box-shadow:
      20px 0px 0px 0px rgba(116, 125, 134, 0.2),
      40px 0px 0px 0px rgb(116, 125, 134);
  }
  100% {
    box-shadow: none;
  }
}
.ka-loading-text {
  margin-top: 15px;
  color: #353c44;
}

.ka-paging-sizes-active {
  display: flex;
  justify-content: space-between;
}

.ka-paging-pages,
.ka-paging-sizes {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  margin: 0;
}

.ka-paging-pages {
  justify-content: flex-end;
}

.ka-paging-page-index,
.ka-paging-size {
  cursor: pointer;
  padding: 5px;
  margin: 10px 5px;
  min-width: 18px;
  border-radius: var(--border-input);
  text-align: center;
  color: var(--gray);
  user-select: none;
}

.ka-paging-page-index-active,
.ka-paging-size-active {
  background-color: var(--pale);
  font-weight: 500;
  color: var(--gray);
}

.ka-header-filter-button-icon {
  font-size: 16px;
  position: absolute;
  top: -1px;
  color: var(--gray);
  fill: currentColor;
}

.ka-thead-fixed {
  top: auto !important;
}
