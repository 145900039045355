.job-tracker-overflow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background-color: var(--white);
  border: 1px solid var(--border);
  border-radius: var(--border-card);
  box-shadow: var(--shadow);
  transition: all 0.3s ease;
  animation: slideIn 0.3s ease;
  pointer-events: all;
}
