.voice-card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid var(--border);
  border-radius: 8px;
  cursor: pointer;
}

.voice-card:hover {
  border: 1px solid var(--border-hover);
}

.voice-card-selected {
  border: 1px solid var(--primary) !important;
  outline: 4px solid var(--primary-pale);
}

.voice-card-header {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.voice-card-header-left {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.voice-card-header-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--border);
  border-radius: 50%;
  overflow: hidden;
}

.voice-card-header-avatar video {
  width: 160%;
  height: 160%;
  object-fit: cover;
  filter: blur(1px) grayscale(100%);
}

.voice-card:hover .voice-card-header-avatar video,
.voice-card-selected .voice-card-header-avatar video {
  filter: blur(1px) grayscale(0%);
}

.voice-card-header-right {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.voice-card-header-details {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.voice-card-header-details-item {
  background-color: var(--pale);
  padding: 2px 8px;
  border-radius: var(--border-card);
}
